<script>
import Layout from "../../../layouts/main";

import Switches from "vue-switches";

export default {
  page: {
    meta: [
      {
        name: "description"
      },
    ],
  },
  components: {
    Layout,
    Switches
  },
  data() {
    return {
      products:[],
      currencies:[],
      category_name: "",
      category_name_en: "",
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchCategories: null,
      ProductsSearchMode: false,
      searchModel: "",
    };
  },
  methods: {
    addProduct() {
      var data = {
        title: "products.add_product",
        inputs: [
          {
            "model":"type",
            "type":"select",
            "label":"popups.type",
            "options":[
              {
                value:"export",
                label:(this.$i18n.locale == 'ar' ? "سلعة مصدرة" :"Export Product")
              },
              {
                value:"import",
                label:(this.$i18n.locale == 'ar' ? "سلعة مستوردة" :"Import Product")
              },
            ]
          },
          {
            "model":"name",
            "type":"text",
            "label":"popups.name_ar"
          },
          {
            "model":"name_en",
            "type":"text",
            "label":"popups.name_en"
          },
          {
            "model":"unit",
            "type":"text",
            "label":"products.unit"
          }, {
            model: "purchase_currency_id",
            type: "select",
            options: (() => {
              return this.currencies.map((data) => {
                return { value: data.id, label: (this.$i18n.locale == 'ar' ? data.name : data.name_en) };
              });
            })(),
            label: "products.purchase_currency",
          },
          {
            "model":"purchase_price",
            "type":"number",
            "label":"products.purchase_price"
          },{
            model: "sale_currency_id",
            type: "select",
            options: (() => {
              return this.currencies.map((data) => {
                return { value: data.id, label: (this.$i18n.locale == 'ar' ? data.name : data.name_en) };
              });
            })(),
            label: "products.sale_currency",
          },
          {
            "model":"sale_price",
            "type":"number",
            "label":"products.sale_price"
          }
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.category_id = this.$route.params.id
              this.http.post("products", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editProduct(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            "model":"type",
            "type":"select",
            "label":"popups.type",
            value:app.type,
            "options":[
              {
                value:"export",
                label:(this.$i18n.locale == 'ar' ? "سلعة مصدرة" :"Export Product")
              },
              {
                value:"import",
                label:(this.$i18n.locale == 'ar' ? "سلعة مستوردة" :"Import Product")
              },
            ]
          },
          {
            "model":"name",
            "type":"text",
            "label":"popups.name_ar",
            "value":app.name
          },
          {
            "model":"name_en",
            "type":"text",
            "label":"popups.name_en",
            "value":app.name_en
          },
          {
            "model":"unit",
            "type":"text",
            "label":"products.unit",
            value:app.unit
          },{
            model: "purchase_currency_id",
            type: "select",
            options: (() => {
              return this.currencies.map((data) => {
                return { value: data.id, label: (this.$i18n.locale == 'ar' ? data.name : data.name_en) };
              });
            })(),
            label: "products.purchase_currency",
            value:app.purchase_currency_id
          },
          {
            "model":"purchase_price",
            "type":"number",
            "label":"products.purchase_price",
            value:app.purchase_price
          },
          {
            model: "sale_currency_id",
            type: "select",
            options: (() => {
              return this.currencies.map((data) => {
                return { value: data.id, label: (this.$i18n.locale == 'ar' ? data.name : data.name_en) };
              });
            })(),
            label: "products.sale_currency",
            value:app.sale_currency_id
          },
          {
            "model":"sale_price",
            "type":"number",
            "label":"products.sale_price",
            value:app.sale_price
          }
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("products", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    deleteProduct(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("products", app.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },
    updateStatus(product) {
      product.is_active = !product.is_active;
      this.http
          .put("products", product?.id, { is_active: product.is_active })
          .then((res) => {
            if (res.status) {
              this.get(this.page);
            }
          });
    },
    search() {
      if(this.searchModel.length==0){
        this.ProductsSearchMode = false
        return this.get(this.page)
      }
      this.ProductsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("products/search", {
          search: this.searchModel,
          category_id:this.$route.params.id,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.products = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.ProductsSearchMode = false;
      this.get(this.page);
    },
    get(page) {
      console.log(page);
      this.http
        .post("categories/paginate/"+this.$route.params.id+"/products", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            this.category_name = res.data.name
            this.category_name_en = res.data.name_en
          res.data.products.forEach(product=> product.is_active = product.is_active ? true : false)
            this.products =  res.data.products
        });
    },
    getCurrencies() {
      this.http.get("currencies/active").then((res) => {
        if (res.status) {
          this.currencies = res.data;
        }
      });
    }
  },
  created() {
    this.get(1);
    this.getCurrencies()
  },
};
</script>

<template>
  <Layout>

    <div class="row">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/categories`">
            <h4 class="m-0">{{ $t('menu.menuitems.categories.text') }}</h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>
            {{
              $i18n.locale == "ar"
                ? "منتجات " + category_name
                : category_name_en + " Products"
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
                @keyup="search()"
                type="text"
                class="form-control fa-lg text-light"
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                :placeholder="$t('popups.search')"
                v-model="searchModel"
                style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
            class="btn-close text-light"
            v-if="ProductsSearchMode"
            @click="cancelappsearchMode()"
        ></button>
        <button
            type="button"
            @click="addProduct()"
            class="btn btn-light float-end mb-4"
        >
          <span
              class="bx bxs-plus-circle float-end fa-2x"
              style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>


    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope='col'>{{$t('categories.id')}}</th>
          <th scope='col'>{{$t('popups.type')}}</th>
          <th scope='col'>{{$t('categories.name')}}</th>
          <th scope='col'>{{$t('categories.name_en')}}</th>
          <th scope='col'>{{$t('products.unit')}}</th>
          <th scope='col'>{{$t('products.purchase_price')}}</th>
          <th scope='col'>{{$t('products.sale_price')}}</th>
          <th scope='col'>{{$t('products.is_active')}}</th>
          <th scope='col'>{{$t('categories.created')}}</th>
          <th scope='col'>{{$t('categories.updated')}}</th>
          <th scope='col'>{{$t('categories.operations')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, index) in products" :key="app" class="text-center">
          <td>{{ index+1 }}</td>
          <td>{{app.type=='export' ? (this.$i18n.locale == 'ar' ? "سلعة مصدرة" :"Export Product") : (this.$i18n.locale == 'ar' ? "سلعة مستوردة" :"Import Product") }}</td>
          <td>{{app.name}}</td>
          <td>{{app.name_en}}</td>
          <td>{{app.unit}}</td>
          <td>{{processCurrency(app.purchase_currency,app.purchase_price,true)}}</td>
          <td>{{processCurrency(app.sale_currency,app.sale_price,true)}}</td>
          <td>
            <switches
                @change="updateStatus(app)"
                :value="app.is_active"
                color="success"
                class="ms-1"
            ></switches>
          </td>
          <td>{{app.created.split('T')[0]}}</td>
          <td>{{app.updated.split('T')[0]}}</td>
          <td style="white-space: nowrap;">
            <a
                class="btn btn-primary btn-sm"
                @click="editProduct(app)"
                href="javascript: void(0);"
                role="button"
            >{{ $t("popups.edit") }}</a
            >
            <a
                class="btn btn-danger btn-sm"
                href="javascript: void(0);"
                role="button"
                @click="deleteProduct(app)"
            >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages> 1">
      <!--   Apps  paginate     -->
      <ul class="paginate paginate-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




